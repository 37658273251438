import * as React from 'react';

import Container from './container'

export class Form extends React.Component {
    render() {
        return <Container />
    }
}

export default Form
