import * as React from 'react';
import { render } from 'react-dom';
import App from './app';


render(
    <App />,
    document.getElementById('app'),
);

import '../css/app.css';
